import styled from "styled-components";
import React from "react";

const StyledCard = styled.div`
  margin-top: 20vh !important;
  width: 25%;
  margin: auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding: 30px !important;
  @media screen and (max-width: 768px) {
    margin-top: 10px !important;
    width: 80%;
  }
`;

const SplashCard = ({ children }) => {
  return <StyledCard className="box">{children}</StyledCard>;
};

export default SplashCard;
