import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 10px 0px;
`;

const Label = styled.div`
  display: block;
  color: rgba(0, 0, 0, 0.4);

  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  /* letter-spacing: 0.2em; */
`;

const Field = styled.div`
  /* background-color: rgba(0, 0, 0, 0.05); */
  color: rgba(0, 0, 0, 0.8);
  /* color: black; */
  width: 100%;
  outline: none;
  padding: 16px 0px;
  box-sizing: border-box;
  border: 0;
  border-radius: 4px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
`;

const Detail = props => {
  let { labels, data } = props;
  // console.log(data);
  return (
    <Wrapper>
      {labels.map(label => {
        return (
          <React.Fragment key={label.name}>
            <Label>{label.name}</Label>
            <Field>{data[label.value] ? data[label.value] : "-"}</Field>
          </React.Fragment>
        );
      })}
    </Wrapper>
  );
};

export default Detail;
