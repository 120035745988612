import React, { Component } from "react";
import Detail from "./common/detail";
import styled from "styled-components";
import Popup from "./common/popup";
import EditProfileForm from "./editProfileForm";
import axios from "axios";
import { Auth } from "aws-amplify";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import environment from "../environment/environment"

const { API_URL } = environment;


const override = css`
  display: block;
  margin-left 10px;
  vertical-align: middle;
`;

const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
`;

const StyledButton = styled.a`
  background-color: #1d88f2 !important;
  border-radius: 16px !important;
  font-size: 15px !important;
  padding: 20px 40px !important;
  font-family: Roboto !important;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

class ProfileOverview extends Component {
  state = {
    labels: [
      { name: "Company Name", value: "companyName" }
      // { name: "First Name", value: "firstName" },
      // { name: "Last Name", value: "lastName" },
      // { name: "Address", value: "address" },
      // { name: "City", value: "city" },
      // { name: "Zip", value: "zip" }
    ],
    isEditProfilePopupOpen: false,
    hideMessage: true,
    companyInfo: {
      companyName: ""
      // address: "",
      // city: "",
      // firstName: "",
      // lastName: "",
      // username: "",
      // email: "",
      // zip: ""
    },
    toast: null,
    isLoading: true,
    didFetchProfileInfo: false
  };

  async componentDidMount() {
    const company = await Auth.currentUserInfo();
    const thirdPartyID = company.attributes.sub;
    try {
      const { data: companyInfo } = await axios.get(
        `${API_URL}/api/company/${thirdPartyID}`
      );
      this.setState({
        companyInfo,
        isLoading: false,
        didFetchProfileInfo: true
      });
    } catch (err) {
      this.setState({
        isLoading: false,
        didFetchProfileInfo: false
      });
    }
  }

  openPopup = () => {
    this.setState({ isEditProfilePopupOpen: true, hideMessage: true });
  };

  closePopup = () => {
    this.setState({ isEditProfilePopupOpen: false });
    this.setState({ toast: null });
  };

  handleProfileSave = async updatedCoachInfo => {
    const { coachInfo: previousCoachInfo } = this.state;
    let mergedUpdatedCoachInfo = {
      ...this.state.coachInfo,
      ...updatedCoachInfo
    };
    this.setState({ coachInfo: mergedUpdatedCoachInfo });
    try {
      const coach = await Auth.currentUserInfo();
      const coachID = coach.attributes.sub;
      try {
        await axios.put(
          "https://ysu0emvb1f.execute-api.us-east-1.amazonaws.com/Prod/api/coaches/" +
          coachID,
          updatedCoachInfo
        );

        this.setupToast(true, "Profile Successfully Saved!");
      } catch (err) {
        this.setState({ coachInfo: previousCoachInfo });
        this.setupToast(false, "Network Error, Please Try Again!");
      }
    } catch (err) {
      this.setState({ coachInfo: previousCoachInfo });

      this.setupToast(false, "Network Error, Please Try Again!");
    }
  };

  setupToast = (isSuccess, message) => {
    let toast = { ...this.state.toast };
    toast.message = message;
    toast.isSuccess = isSuccess;
    this.setState({ toast });
  };

  render() {
    let {
      labels,
      companyInfo,
      isEditProfilePopupOpen,
      toast,
      isLoading,
      didFetchProfileInfo
    } = this.state;

    if (isLoading && !didFetchProfileInfo) {
      return (
        <CenteredWrapper>
          Fetching profile...
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={20}
            color={"#4a4a4a"}
            loading={isLoading}
          />
        </CenteredWrapper>
      );
    } else if (!isLoading && !didFetchProfileInfo) {
      return (
        <CenteredWrapper>
          Could not fetch your profile, please try again.
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={20}
            color={"#4a4a4a"}
            loading={isLoading}
          />
        </CenteredWrapper>
      );
    }

    return (
      <React.Fragment>
        <Detail labels={labels} data={companyInfo} />
        {/* <ButtonWrapper>
          <StyledButton className="button is-primary" onClick={this.openPopup}>
            Edit Profile
          </StyledButton>
        </ButtonWrapper> */}
        {/* <Popup
          isActive={isEditProfilePopupOpen}
          title="Edit Profile"
          close={this.closePopup}
          data={coachInfo}
        >
          <EditProfileForm
            toast={toast}
            data={coachInfo}
            close={this.closePopup}
            onProfileSave={this.handleProfileSave}
          />
        </Popup> */}
      </React.Fragment>
    );
  }
}

export default ProfileOverview;
