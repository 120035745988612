import React from "react";
import LoginForm from "../loginForm";
import SplashCard from "../splashCard";

const Login = props => {
  console.log("hello");
  return (
    <SplashCard>
      <LoginForm onChangeAuthentication={props.onChangeAuthentication} />
    </SplashCard>
  );
};

export default Login;
