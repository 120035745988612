import React from "react";
import {
  Modal,
  ModalBackground,
  Delete,
  ModalCardTitle,
  ModalCardHeader,
  ModalCardBody,
  ModalCard,
  ModalCardFooter
} from "bloomer";
import styled from "styled-components";

const PopupHeader = styled(ModalCardHeader)`
  background-color: white;
  border: 0px;
  border-radius: "20px 20px 0px 0px";
`;

const PopupFooter = styled(ModalCardFooter)`
  background-color: white;
  border: 0px;
  border-radius: "0px 0px 20px 20px";
`;

const Popup = ({ isActive, title, name, close, children }) => {
  return (
    <Modal isActive={isActive}>
      <ModalBackground />
      <ModalCard>
        <PopupHeader>
          <ModalCardTitle>{title}</ModalCardTitle>
          <Delete
            onClick={() => {
              close(name);
            }}
          />
        </PopupHeader>
        <ModalCardBody>{children}</ModalCardBody>
        <PopupFooter />
      </ModalCard>
    </Modal>
  );
};

export default Popup;
