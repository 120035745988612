import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import DashboardNavbar from "../dashboardNavbar";
import styled from "styled-components";
import { Columns, Column } from "bloomer";
import SideMenu from "../sideMenu";
import Overview from "../pages/overview";
import ApiKey from "../pages/apikey";
import Documentation from "../pages/documentation";

const Wrapper = styled.div`
  position: relative;
  top: 0;
  height: 100vh;
  background-color: #000;
`;

const SidePanel = styled.div`
  width: 260px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  @media screen and (max-width: 1087px) {
    display: none;
  }
`;

const MainPanel = styled.div`
  background-color: #eee;
  margin-left: 260px;
  padding: 20px;
  min-height: 100vh;
  @media screen and (max-width: 1087px) {
    width: 100%;
    margin-left: 0px;
  }
}
`;

class Dashboard extends Component {
  state = { currentPage: "API Key" };

  onPageChange = page => {
    this.setState({ currentPage: page });
  };

  menu = [
    { icon: "vpn_key", label: "API Key", path: "/dashboard/apikey" },
    {
      icon: "description",
      label: "Documentation",
      path: "/dashboard/documentation"
    }
  ];

  render() {
    let { currentPage } = this.state;
    return (
      <Wrapper>
        <SidePanel>
          <SideMenu currentPage={currentPage} menu={this.menu} />
        </SidePanel>
        <MainPanel>
          <Columns>
            <Column isSize="full">
              <DashboardNavbar
                currentPage={this.state.currentPage}
                onChangeAuthentication={this.props.onChangeAuthentication}
              />
            </Column>
          </Columns>
          <Switch>
            <Route
              path="/dashboard/apikey"
              exact
              render={() => <ApiKey onPageChange={this.onPageChange} />}
            />
            <Route
              path="/dashboard/documentation"
              exact
              render={() => <Documentation onPageChange={this.onPageChange} />}
            />
            <Route
              path="/dashboard/account/overview"
              exact
              render={() => <Overview onPageChange={this.onPageChange} />}
            />
            <Redirect from="/dashboard" exact to="/dashboard/apikey" />
            <Redirect to="/not-found" />
          </Switch>
        </MainPanel>
      </Wrapper>
    );
  }
}

export default Dashboard;
