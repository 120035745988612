import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledNavBarMenuLink = styled(Link)`
  font-weight: 500 !important;
  color: white;
  /* background-color: rgba(255, 255, 255, 0.1); */
  /* background-color: rgba(0, 0, 0, 0.7); */
  background-color: ${props =>
    props.splash ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.7)"};
  margin: 5px 0px;
  border-radius: 4px;
  padding: 20px;
  display: block;
  &:visited {
    color: white;
  }
  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
`;

const NavbarMenuLink = props => {
  return (
    <StyledNavBarMenuLink splash={props.splash} to={props.path}>
      {props.label}
    </StyledNavBarMenuLink>
  );
};

export default NavbarMenuLink;
