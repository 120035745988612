import React from "react";
import styled from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";

const MessageWrapper = styled.div`
  background-color: ${props => (props.success ? "#23d160" : "red")};
  visibility: ${props => (props.show ? "visible" : "hidden")};
  display: flex;
  justify-content: center;
  padding: 20px 0px;
`;

const MessageLabel = styled.div`
  display: block;
  color: white;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  white-space: pre-line;
`;

const Message = props => {
  let { isSuccess, message } = props;
  if (!isSuccess) {
    return (
      <MessageWrapper show>
        <MessageLabel>{message}</MessageLabel>
      </MessageWrapper>
    );
  }
  return (
    <MessageWrapper success show>
      <MessageLabel>{message}</MessageLabel>
    </MessageWrapper>
  );
};

export default Message;
