import React from "react";
import SplashCard from "./splashCard";
import ChangePasswordForm from "./changePasswordForm";

const ChangePassword = props => {
  console.log("hello");
  return (
    <SplashCard>
      <ChangePasswordForm />
    </SplashCard>
  );
};

export default ChangePassword;
