import React, { Component } from "react";
import Card from "../common/card";
import { Columns } from "bloomer";
import ProfileOverview from "../profileOverview";

class Overview extends Component {
  componentWillMount() {
    const { onPageChange } = this.props;
    onPageChange("Overview");
  }

  render() {
    return (
      <Columns>
        <div className="column is-flex">
          <Card>
            <ProfileOverview />
          </Card>
        </div>
        <div className="column is-flex">
          <Card style={{ width: "100% !important" }}>
            {/* <SubscriptionOverview /> */}
          </Card>
        </div>
      </Columns>
    );
  }
}

export default Overview;
