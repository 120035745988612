import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Redirect, Switch, Route } from 'react-router-dom';
import Dashboard from './components/layouts/dashboard';
import { Auth } from 'aws-amplify';
import Splash from './components/layouts/splash';
import NotFound from './components/pages/notfound';
import axios from 'axios';
import environment from './environment/environment';

const { API_URL } = environment;

library.add(faUser);

class App extends Component {
    state = {
        isAuthenticated: false,
        isAuthenticating: true,
    };
    async componentDidMount() {
        try {
            await Auth.currentSession();
            const company = await Auth.currentUserInfo();
            const thirdPartyID = company.attributes.sub;
            let { data: retrievedCompany } = await axios.get(
                `${API_URL}/api/company/${thirdPartyID}`
            );
            if (!retrievedCompany.isApproved) {
                this.props.history.push('/splash/underreview');
            } else {
                this.setState({ isAuthenticated: true });
                this.props.history.push('/');
            }
        } catch (e) {
            if (e !== 'No current user') {
                // alert(e);
            }
        }
        this.setState({ isAuthenticating: false });
    }

    isUserAuthenticated = (authenticated) => {
        this.setState({ isAuthenticated: authenticated });
        console.log(this.state);
    };

    render() {
        let dashboardRoute = null;
        if (this.state.isAuthenticated) {
            dashboardRoute = (
                <Route
                    path="/dashboard"
                    render={() => (
                        <Dashboard
                            onChangeAuthentication={this.isUserAuthenticated}
                        />
                    )}
                />
            );
        }
        return (
            !this.state.isAuthenticating && (
                <React.Fragment>
                    <Switch>
                        <Route
                            path="/splash"
                            render={() => (
                                <Splash
                                    onChangeAuthentication={
                                        this.isUserAuthenticated
                                    }
                                />
                            )}
                        />
                        {dashboardRoute}
                        <Route path="/not-found" component={NotFound} />
                        <Redirect
                            from="/"
                            exact
                            to={
                                this.state.isAuthenticated
                                    ? '/dashboard'
                                    : '/splash'
                            }
                        />
                        <Redirect to="/not-found" />
                    </Switch>
                </React.Fragment>
            )
        );
    }
}

export default App;
