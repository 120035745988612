import React, { Component } from "react";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin-left 10px;
  vertical-align: middle;
`;

const Wrapper = styled.div`
  margin: 10px 0px;
`;

const ButtonLabel = styled.span`
  vertical-align: middle;
`;

const Label = styled.div`
  display: block;
  margin: 0 0 10px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.2em;
`;

const OptionalMessage = styled.span`
  font-size: 10px;
  letter-spacing: 0em;
`;

const StyledInput = styled.input`
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  outline: none;
  padding: 16px 20px;
  box-sizing: border-box;
  border: 0;
  border-radius: 4px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
`;

const StyledButton = styled.button`
  font-family: Roboto;
  font-weight: 500;
  font-size: 15px !important;
  width: 100% !important;
  margin: auto !important;
  height: 100%;
  padding: 12px 20px !important;
`;

const ErrorMessage = styled.div`
  color: red;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  /* margin: 15px 0px; */
`;

const Message = styled.div`
  text-align: center;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  margin: 15px 0px;
`;

class Form extends Component {
  handleChange = event => {
    event.preventDefault();
    const fields = { ...this.state };
    fields[event.target.name] = event.target.value;
    this.setState(fields);
  };

  renderOptionalInput = (
    label,
    optionalMessage,
    name,
    value,
    error,
    type = "text"
  ) => {
    return (
      <Wrapper>
        <Label>
          {label} <OptionalMessage>({optionalMessage})</OptionalMessage>
        </Label>
        <StyledInput
          type={type}
          name={name}
          value={value}
          onChange={this.handleChange}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Wrapper>
    );
  };

  renderInput = (label, name, value, error, type = "text") => {
    return (
      <Wrapper>
        <Label>{label}</Label>
        <StyledInput
          type={type}
          name={name}
          value={value}
          onChange={this.handleChange}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Wrapper>
    );
  };

  renderButton = (label, isLoading = false) => {
    return (
      <StyledButton
        className="button is-block is-info is-medium is-fullwidth"
        disabled={isLoading}
      >
        <ButtonLabel>{label}</ButtonLabel>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={20}
          color={"white"}
          loading={isLoading}
        />
      </StyledButton>
    );
  };

  renderMessage = message => {
    return <Message>{message}</Message>;
  };
}

export default Form;
