import styled from "styled-components";
import React, { Component } from "react";

const Wrapper = styled.div`
  margin: 10px 0px;
`;

const StyledInput = styled.input`
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  outline: none;
  padding: 16px 20px;
  box-sizing: border-box;
  border: 0;
  border-radius: 4px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
`;

const Label = styled.div`
  display: block;
  margin: 0 0 10px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
`;

const MessageLabel = styled.div`
  display: block;
  color: white;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
`;

const ActionButton = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: #1d88f2 !important;
  border-radius: 20px !important;
  font-size: 15px !important;
  font-family: Roboto !important;
  padding: 20px 40px !important;
`;

const CancelButton = styled.div`
  background-color: #3c4858 !important;
  border-radius: 20px !important;
  font-size: 15px !important;
  padding: 20px 40px !important;
  font-family: Roboto !important;
  margin-right: 40px;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

const MessageWrapper = styled.div`
  background-color: ${props => (props.success ? "#23d160" : "red")};
  visibility: ${props => (props.show ? "visible" : "hidden")};
  display: flex;
  justify-content: center;
  padding: 20px 0px;
`;

class EditForm extends Component {
  handleChange = event => {
    event.preventDefault();
    const fields = { ...this.state.fields };
    fields[event.target.name].value = event.target.value;
    this.setState({ fields });
  };

  renderMessage = (success, message) => {
    console.log(`success ${success}`);
    if (!success) {
      return (
        <MessageWrapper show>
          <MessageLabel>{message}</MessageLabel>
        </MessageWrapper>
      );
    }
    return (
      <MessageWrapper success show>
        <MessageLabel>{message}</MessageLabel>
      </MessageWrapper>
    );
  };

  renderInput = (label, name, value, required, type = "text") => {
    return (
      <Wrapper key={label}>
        <Label>{label}</Label>
        <StyledInput
          type={type}
          name={name}
          value={value}
          required={required}
          onChange={this.handleChange}
        />
      </Wrapper>
    );
  };

  renderButtons = (onCancel, onSubmit, actionTitle = "Save Changes") => {
    return (
      <ButtonWrapper>
        <CancelButton className="button is-primary" onClick={onCancel}>
          Cancel
        </CancelButton>
        <ActionButton className="button is-primary" onClick={onSubmit}>
          {actionTitle}
        </ActionButton>
      </ButtonWrapper>
    );
  };
}

export default EditForm;
