import React from "react";
import { withRouter } from "react-router-dom";
import Form from "./common/form";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import SplashMessage from "./splashMessage";

const SyncMessage = styled.div`
  font-size: 10px;
`;

class ChangePasswordForm extends Form {
  state = {
    email: "",
    code: "",
    newPassword: "",
    confirmNewPassword: "",
    confirmationCode: "",
    password: "",
    confirmPasword: "",
    errors: {},
    user: null,
    isSigningUp: false,
    toast: null,
    isLoading: false
  };

  componentDidMount() {
    const email = this.props.location.state.email;
    console.log(`email: ${email}`);
    this.setState({ email });
  }

  validate = () => {
    const { code, newPassword, confirmNewPassword } = this.state;
    const errors = {};

    const passwordLengthRegex = /^(?=.{6,})/;
    const passwordCapitalRegex = /^(?=.*[A-Z])/;
    const passwordNumericRegex = /^(?=.*[0-9])/;
    const passwordSymbolRegex = /(?=.[!@#\$%\^&])/;

    if (code.trim() == "") errors.code = "Code is required.";

    if (newPassword.trim() == "") errors.newPassword = "Password is required.";
    if (!passwordLengthRegex.test(newPassword.trim()))
      errors.newPassword = "Password must be atleast 6 characters long.";
    if (!passwordCapitalRegex.test(newPassword.trim()))
      errors.newPassword = "Password must contain atleast 1 uppercase letter.";
    if (!passwordNumericRegex.test(newPassword.trim()))
      errors.newPassword = "Password must contain atleast 1 numeric character.";
    if (!passwordSymbolRegex.test(newPassword.trim()))
      errors.newPassword =
        "Password must contain atleast 1 symbolic character.";
    if (confirmNewPassword.trim() == "")
      errors.confirmNewPassword = "You must confirm your password.";
    if (confirmNewPassword.trim() !== newPassword.trim()) {
      errors.confirmNewPassword = "Your passwords must match.";
      console.log("I got here");
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };

  validateOnChange = ({ name, value }) => {
    const errors = { ...this.state.errors };
    const { newPassword, confirmNewPassword, code } = this.state;
    if (name === "code") {
      if (value.trim() === "") {
        console.log("EMPTY STRING");
        errors[name] = "Email is required.";
      } else delete errors[name];
    }
    if (name === "newPassword") {
      const passwordLengthRegex = /^(?=.{6,})/;
      const passwordCapitalRegex = /^(?=.*[A-Z])/;
      const passwordNumericRegex = /^(?=.*[0-9])/;
      const passwordSymbolRegex = /(?=.[!@#\$%\^&])/;
      if (value.trim() === "") return "Password is required.";
      else if (!passwordLengthRegex.test(value.trim()))
        errors[name] = "Password must be atleast 6 characters long.";
      else if (!passwordCapitalRegex.test(value.trim()))
        errors[name] = "Password must contain atleast 1 uppercase letter.";
      else if (!passwordNumericRegex.test(value.trim()))
        errors[name] = "Password must contain atleast 1 numeric character.";
      else if (!passwordSymbolRegex.test(value.trim())) {
        console.log(value.trim());
        errors[name] = "Password must contain atleast 1 symbolic character.";
      } else delete errors[name];

      if (value.trim() !== confirmNewPassword)
        errors["confirmPassword"] = "Passwords do not match";
      else delete errors["confirmPassword"];
    }
    if (name === "confirmNewPassword") {
      if (value.trim() === "") errors[name] = "You must confirm your password";
      else if (value.trim() !== newPassword)
        errors[name] = "Passwords do not match";
      else delete errors[name];
    }
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const { account } = this.state;

    if (name === "email") {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value.trim() === "") return "Email is required.";
      if (!emailRegex.test(value.trim().toLowerCase()))
        return "This is not a valid email";
    }
    if (name === "password") {
      const passwordLengthRegex = /^(?=.{6,})/;
      const passwordCapitalRegex = /^(?=.*[A-Z])/;
      const passwordNumericRegex = /^(?=.*[0-9])/;
      const passwordSymbolRegex = /(?=.[!@#\$%\^&])/;
      if (value.trim() === "") return "Password is required.";
      if (!passwordLengthRegex.test(value.trim()))
        return "Password must be atleast 6 characters long.";
      if (!passwordCapitalRegex.test(value.trim()))
        return "Password must contain atleast 1 uppercase letter.";
      if (!passwordNumericRegex.test(value.trim()))
        return "Password must contain atleast 1 numeric character.";
      if (!passwordSymbolRegex.test(value.trim()))
        return "Password must contain atleast 1 symbolic character.";
    }
    if (name === "confirmPassword") {
      if (value.trim() === "") return "You must confirm your password";
      if (value.trim() !== account.password) return "Your passwords must match";
    }
  };

  handleChange = ({ target: input }) => {
    console.log(input.name);
    const errors = this.validateOnChange(input);

    // const account = { ...this.state.account };
    // account[input.name] = input.value;
    this.setState({ [input.name]: input.value, errors: errors || {} });
  };

  handleSubmit = async event => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {}, isLoading: true });
    if (errors) return;

    let toast = {
      message: "Changing password",
      isSuccess: true
    };

    this.setState({ isSigningUp: false, toast });

    const { email, newPassword, code } = this.state;

    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);

      let toast = {
        message: "Password successfully changed!",
        isSuccess: true
      };

      this.setState({ isSigningUp: false, toast, isLoading: false });
    } catch (err) {
      let toast = {
        message:
          "Failed to change password, you may have entered an invalid code",
        isSuccess: false
      };

      this.setState({ isSigningUp: false, toast, isLoading: false });
    }
    // let toast = {
    //   message: "Changing Password...",
    //   isSuccess: false
    // };
    // Auth.forgotPasswordSubmit(username, code, newPassword)
    //   .then(data => console.log(data))
    //   .catch(err => console.log(err));
  };

  parseEvents(oldEvents) {
    console.log(oldEvents);
  }

  render() {
    const {
      account,
      errors,
      isSigningUp,
      toast,
      code,
      newPassword,
      confirmNewPassword,
      isLoading
    } = this.state;

    let message = null;

    if (toast !== null)
      message = (
        <SplashMessage isSuccess={toast.isSuccess} message={toast.message} />
      );

    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          {message}
          <SyncMessage>
            Please enter the code that was sent to your email inorder to change
            your password.
          </SyncMessage>
          {this.renderInput("Code", "code", code, errors.code)}
          {this.renderInput(
            "New Password",
            "newPassword",
            newPassword,
            errors.newPassword,
            "password"
          )}
          {this.renderInput(
            "Confirm New Password",
            "confirmNewPassword",
            confirmNewPassword,
            errors.confirmNewPassword,
            "password"
          )}

          {this.renderButton("Change Password", isLoading)}
        </form>
      </React.Fragment>
    );
  }
}

export default withRouter(ChangePasswordForm);
