import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledNavbarLink = styled(Link)`
  font-family: Roboto !important;
  font-size: 12px !important;
  background-color: transparent !important;
  color: white;
  padding: 20px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
`;

const StyledSpan = styled.span`
  font-weight: 500 !important;
`;

const StyledIcon = styled.i`
  padding-right: 10px;
`;

const NavbarLink = props => {
  return (
    <StyledNavbarLink to={props.path} className="button is-primary">
      <StyledIcon className="material-icons">{props.icon}</StyledIcon>
      <StyledSpan>{props.label}</StyledSpan>
    </StyledNavbarLink>
  );
};

export default NavbarLink;
