import React from "react";
import { Auth } from "aws-amplify";
import Form from "./common/form";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import SplashMessage from "./splashMessage.jsx";
import axios from "axios";
import environment from "../environment/environment"

const { API_URL } = environment;


const override = css`
  display: block;
  margin-left 10px;
  vertical-align: middle;
`;

const ButtonLabel = styled.span`
  vertical-align: middle;
`;

const Message = styled.div`
  text-align: center;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  margin: 15px 0px;
`;

const StyledButton = styled.button`
  font-family: Roboto;
  font-weight: 500;
  font-size: 15px !important;
  width: 100% !important;
  margin: auto !important;
  height: 100%;
  padding: 12px 20px !important;
  margin-top: 10px !important;
`;

class ConfirmationForm extends Form {
  state = {
    email: "",
    password: "",
    confirmationCode: "",
    isResending: false,
    isConfirming: false,
    errors: {},
    toast: null
  };

  componentDidMount() {
    const { email, password } = this.props.location.state.user;
    this.setState({ email, password });
  }

  validate = () => {
    const { confirmationCode } = this.state;
    const errors = {};

    if (confirmationCode.trim() == "")
      errors["confirmationCode"] = "Confirmation code is required.";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  validateProperty = ({ name, value }) => {
    if (name === "confirmationCode") {
      if (value.trim() === "") return "Confirmation code is required.";
    }
    return null;
  };

  handleChange = ({ target: input }) => {
    console.log(input.name);
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else delete errors[input.name];

    // this.setState({ account, errors });
    this.setState({ confirmationCode: input.value, errors });
  };

  handleSubmit = async event => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      return;
    }
    this.setState({ isConfirming: true });

    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      await Auth.signIn(this.state.email, this.state.password);
      const company = await Auth.currentUserInfo();
      const thirdPartyID = company.attributes.sub;
      let { data: retrievedCompany } = await axios.get(
        `${API_URL}/api/company/${thirdPartyID}`
      );

      if (!retrievedCompany.isApproved) {
        this.props.history.push("/splash/underreview");
      } else {
        this.props.onChangeAuthentication(true);
        this.props.history.push("/");
      }
    } catch (e) {
      let toast = {
        message: e.message,
        isSuccess: false
      };
      this.setState({ isConfirming: false, toast });
    }
  };

  handleResendCode = async () => {
    this.setState({ isResending: true });
    const { email } = this.state;
    await Auth.resendSignUp(email);
    this.setState({ isResending: false });
  };

  render() {
    console.log(this.props);
    const { isResending, isConfirming, errors, toast } = this.state;

    let message = null;

    if (toast !== null)
      message = (
        <SplashMessage isSuccess={toast.isSuccess} message={toast.message} />
      );

    return (
      <React.Fragment>
        {message}
        <form onSubmit={this.handleSubmit}>
          <Message>A confirmation code has been sent to your email.</Message>
          {this.renderInput(
            "Confirmation Code",
            "confirmationCode",
            this.confirmationCode,
            errors.confirmationCode,
            "text"
          )}
          {this.renderButton("VERIFY", isConfirming)}
        </form>
        <StyledButton
          className="button is-block is-info is-medium is-fullwidth"
          disabled={isResending}
          onClick={this.handleResendCode}
        >
          <ButtonLabel>RESEND CODE</ButtonLabel>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={20}
            color={"white"}
            loading={isResending}
          />
        </StyledButton>
      </React.Fragment>
    );
  }
}

export default withRouter(ConfirmationForm);
