import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import LogoPNG from "../assets/img/splashlogo.png";
import SplashBG from "../assets/img/splash.jpg";
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #2e363f;
`;

const LogoWrapper = styled.div`
  padding: 20px 0;
  text-align: center;
  /* background: black; */
  width: 200px;
  margin: auto;
`;

// const Logo = stytled.img`
//     : url(${bg});
// ``

const LogoUnderline = styled.hr`
  padding: 0;
  width: 90%;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.3);
  /* background-color: transparent; */
  height: 1px;
`;

const NavWrapper = styled.div`
  height: 100%;
  margin-top: 20px;
  list-style: none;
`;

const NavList = styled.div`
  background-color: transparent;
`;

const NavItem = styled.li`
  margin: 10px 0px;
`;

const NavIcon = styled.i`
  color: white;
  color: rgba(255, 255, 255, 0.8);
  font-size: 24px;
  line-height: 30px;
  float: left;
  margin-right: 20px;
`;

const NavLabel = styled.p`
  line-height: 30px;
  font-size: 14px;
  font-weight: 300;
`;

const StyledNavLink = styled(NavLink)`
  margin: 10px 0px;
  display: block;
  margin: auto;
  width: 90%;
  padding: 10px 15px;
  color: #3c4858;
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  border-radius: 3px;
  text-transform: capitalize;
  &:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  &.active {
    background-color: #1d88f2;
    color: white;
  }
  &.active:hover {
    background-color: #1d88f2 !important;
  }
`;

const SideMenu = ({ logo, menu }) => {
  console.log(LogoPNG);
  return (
    <Wrapper>
      <LogoWrapper>
        <img src={LogoPNG} />
      </LogoWrapper>
      <LogoUnderline />
      <NavWrapper>
        <NavList>
          {menu.map(item => {
            return (
              <NavItem key={item.label}>
                <StyledNavLink to={item.path}>
                  <NavIcon className="material-icons">{item.icon}</NavIcon>
                  <NavLabel>{item.label}</NavLabel>
                </StyledNavLink>
              </NavItem>
            );
          })}
        </NavList>
      </NavWrapper>
    </Wrapper>
  );
};

export default SideMenu;
