import React, { Component } from "react";
import Navbar from "./common/navbar";

class SplashNavbar extends Component {
  navbarMenuLinks = [
    { path: "/splash/login", label: "LOGIN" },
    { path: "/splash/signup", label: "SIGNUP" }
  ];

  navbarLinks = [
    { path: "/splash/login", icon: "person", label: "LOGIN" },
    { path: "/splash/signup", icon: "person_add", label: "SIGNUP" }
  ];

  render() {
    return (
      <Navbar
        type="splash"
        navbarMenuLinks={this.navbarMenuLinks}
        navbarLinks={this.navbarLinks}
      />
    );
  }
}

export default SplashNavbar;
