import React from "react";
import ForgotPasswordForm from "./forgotPasswordForm";
import SplashCard from "./splashCard";

const ForgotPassword = props => {
  console.log("hello");
  return (
    <SplashCard>
      <ForgotPasswordForm
        onChangeAuthentication={props.onChangeAuthentication}
      />
    </SplashCard>
  );
};

export default ForgotPassword;
