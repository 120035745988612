import styled from "styled-components";
import React from "react";
import { Box } from "bloomer";
const StyledCard = styled(Box)`
  width: 100%;
  /* margin: auto; */
  box-shadow: 0 0;
  padding: 30px !important;
  min-height: 50vh;
`;

const SplashCard = ({ children }) => {
  return <StyledCard>{children}</StyledCard>;
};

export default SplashCard;
