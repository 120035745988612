import React from "react";
import { Auth } from "aws-amplify";
import Form from "./common/form";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import SplashMessage from "./splashMessage";

const Message = styled.div`
  text-align: center;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  margin: 15px 0px;
`;

const StyledButton = styled.button`
  font-family: Roboto;
  font-weight: 500;
  font-size: 15px !important;
  width: 100% !important;
  margin: auto !important;
  height: 100%;
  padding: 12px 20px !important;
  margin-top: 10px !important;
`;

class ForgotPasswordForm extends Form {
  state = {
    email: "",
    errors: {},
    toast: null,
    isLoading: false
  };

  validate = () => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const { email } = this.state;
    const errors = {};

    if (email.trim() === "") errors.email = "Email is required.";
    else if (!emailRegex.test(email.trim()))
      errors["email"] = "Email is not valid.";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  validateProperty = ({ name, value }) => {
    if (name === "email") {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value.trim() === "") return "Email is required.";
      else if (!emailRegex.test(value.trim())) return "Email is not valid";
    }
    if (name === "password") {
      if (value.trim() === "") return "Password is required.";
    }

    return null;
  };

  handleChange = ({ target: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else delete errors[input.name];

    let email = { ...this.state };
    email = input.value;

    // this.setState({ account, errors });
    this.setState({ email, errors });
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      const { email } = this.state;

      await Auth.forgotPassword(email);

      await this.props.history.push({
        pathname: "/splash/changePassword",
        state: {
          email: email
        }
      });
    } catch (e) {
      this.setState({
        toast: {
          message: "Email does not exist.",
          isSuccess: false
        },
        isLoading: false
      });
    }
  };

  render() {
    const { email, errors, toast, isLoading } = this.state;

    let message = null;

    if (toast !== null)
      message = (
        <SplashMessage isSuccess={toast.isSuccess} message={toast.message} />
      );

    return (
      <React.Fragment>
        {message}
        <form onSubmit={this.handleSubmit}>
          <Message>Please enter your email.</Message>
          {this.renderInput("Email", "email", email, errors.email)}
          {this.renderButton("SUBMIT", isLoading)}
        </form>
      </React.Fragment>
    );
  }
}

export default withRouter(ForgotPasswordForm);
