import React, { Component } from "react";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import { withRouter, Link } from "react-router-dom";
import NavbarMenuLink from "./common/navbarMenuLink";

import {
  Navbar,
  NavbarBrand,
  NavbarBurger,
  NavbarMenu,
  NavbarEnd,
  NavbarDivider,
  NavbarDropdown,
  NavbarItem,
  NavbarLink
} from "bloomer";

const StyledNavbar = styled(Navbar)`
  background-color: transparent;
`;

const StyledNavbarBurger = styled(NavbarBurger)`
  color: black;
`;

const StyledNavbarMenu = styled(NavbarMenu)`
  background-color: transparent;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  box-shadow: none !important;
`;

const StyledIcon = styled.i`
  font-size: "30px";
`;

const StyleNavbarItem = styled(NavbarItem)`
  cursor: pointer;
`;

const NavbarMenuLogout = styled(NavbarItem)`
  font-weight: 500 !important;
  color: white;
  /* background-color: rgba(255, 255, 255, 0.1); */
  /* background-color: rgba(0, 0, 0, 0.7); */
  background-color: rgba(0, 0, 0, 0.7);
  margin: 5px 0px;
  border-radius: 4px;
  padding: 20px;
  display: block;
  &:visited {
    color: white;
  }
  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
`;

class DashboardNavbar extends Component {
  state = { isActive: false };

  onClickNav = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  handleLogout = async event => {
    await Auth.signOut()
      .then(err => console.log("success"))
      .catch(err => console.log(err.message));
    console.log("doing something");
    this.props.onChangeAuthentication(false);
    this.props.history.push("/");
  };

  render() {
    return (
      <StyledNavbar>
        <NavbarBrand>
          <NavbarItem>{this.props.currentPage}</NavbarItem>
          <StyledNavbarBurger
            isActive={this.state.isActive}
            onClick={this.onClickNav}
          />
        </NavbarBrand>
        <StyledNavbarMenu
          isActive={this.state.isActive}
          onClick={this.onClickNav}
          isHidden="desktop"
        >
          <NavbarMenuLink path={"/dashboard/apikey"} label={"API Key"} />
          <NavbarMenuLink
            path={"/dashboard/documentation"}
            label={"Documentation"}
          />
          <NavbarMenuLink
            path={"/dashboard/account/overview"}
            label={"Account"}
          />
          <NavbarMenuLogout
            onClick={() => {
              this.handleLogout();
            }}
          >
            Logout
          </NavbarMenuLogout>
        </StyledNavbarMenu>
        <NavbarEnd>
          <NavbarItem hasDropdown isHoverable isHidden="touch">
            <NavbarLink>
              <StyledIcon className="material-icons">account_circle</StyledIcon>
            </NavbarLink>
            <NavbarDropdown>
              <Link to="/dashboard/account/overview" className="navbar-item">
                Profile
              </Link>
              <NavbarDivider />
              <StyleNavbarItem href="#" onClick={this.handleLogout}>
                Logout
              </StyleNavbarItem>
            </NavbarDropdown>
          </NavbarItem>
        </NavbarEnd>
      </StyledNavbar>
    );
  }
}

export default withRouter(DashboardNavbar);
