import React, { Component } from "react";
import styled from "styled-components";
import {
  Navbar as BulmaNavbar,
  NavbarBrand,
  NavbarBurger,
  NavbarMenu,
  NavbarEnd,
  Field,
  Control,
  NavbarDropdown,
  NavbarItem,
  NavbarLink as BulmaNavbarLink
} from "bloomer";
import NavbarMenuLink from "./navbarMenuLink";
import NavbarLink from "./navbarLink";
import LogoPNG from "../../assets/img/splashlogo.png";

const StyledNavbar = styled(BulmaNavbar)`
  background-color: transparent;
`;

const StyledNavbarBurger = styled(NavbarBurger)`
  color: white;
`;

const LogoWrapper = styled.div`
  height: 40px;
  width: 180px;
  margin-left: 20px;
  margin-top: 20px;
`;

const Logo = styled.img`
  max-width: 100%;
  height: auto;
`;

const StyledNavbarMenu = styled(NavbarMenu)`
  background-color: transparent;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  box-shadow: none !important;
`;

const StyledIcon = styled.i`
  font-size: "30px";
`;

class Navbar extends Component {
  state = { isActive: false };

  onClickNav = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  render() {
    const navbarMenuLinks = this.props.navbarMenuLinks.map(link => {
      return (
        <NavbarItem isHidden="desktop" key={link.label}>
          <NavbarMenuLink splash path={link.path} label={link.label} />
        </NavbarItem>
      );
    });

    let navbarLinks = null;
    if (this.props.type === "splash") {
      navbarLinks = this.props.navbarLinks.map(link => {
        return (
          <NavbarLink
            key={link.label}
            path={link.path}
            icon={link.icon}
            label={link.label}
          />
        );
      });
    } else if (this.props.type === "dashboard") {
      console.log(this.props.navbarDropDowns);
      navbarLinks = this.props.navbarDropDowns.map(dropdownMenu => {
        return (
          <NavbarItem hasDropdown isHoverable key={dropdownMenu.icon}>
            <BulmaNavbarLink>
              <StyledIcon className="material-icons">
                {dropdownMenu.icon}
              </StyledIcon>
            </BulmaNavbarLink>
            <NavbarDropdown>
              {dropdownMenu.menus.map(menuEntry => {
                console.log(menuEntry);
                return (
                  <NavbarItem
                    href="#/"
                    onClick={menuEntry.handler}
                    key={menuEntry.label}
                  >
                    {menuEntry.label}
                  </NavbarItem>
                );
              })}
            </NavbarDropdown>
          </NavbarItem>
        );
      });
    }

    return (
      <StyledNavbar>
        <NavbarBrand>
          <LogoWrapper>
            <img src={LogoPNG} style={{ width: "100%", height: "auto" }} />
          </LogoWrapper>
          <StyledNavbarBurger
            isActive={this.state.isActive}
            onClick={this.onClickNav}
          />
        </NavbarBrand>
        <StyledNavbarMenu
          isActive={this.state.isActive}
          onClick={this.onClickNav}
        >
          {navbarMenuLinks}
        </StyledNavbarMenu>
        <NavbarEnd>
          <NavbarItem isHidden="touch">
            <Field isGrouped>
              <Control>{navbarLinks}</Control>
            </Field>
          </NavbarItem>
        </NavbarEnd>
      </StyledNavbar>
    );
  }
}

export default Navbar;
