import React from "react";
import ConfirmationForm from "../confirmationForm";
import SplashCard from "../splashCard";

const Confirmation = props => {
  return (
    <SplashCard>
      <ConfirmationForm onChangeAuthentication={props.onChangeAuthentication} />
    </SplashCard>
  );
};

export default Confirmation;
