import React from "react";
import styled from "styled-components";

const NotFoundWrapper = styled.div`
  margin-top: 25vh;
  text-align: center;
`;

const NotFound404 = styled.div`
  position: relative;
  height: 240px;
`;

const Message404 = styled.h1`
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 252px;
  font-weight: 900;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px;
`;

const Span404 = styled.span`
  text-shadow: -8px 0px 0px #fff;
`;

const PageNotfound = styled.h3`
  font-family: "Cabin", sans-serif;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #262626;
  margin: 0px;
  letter-spacing: 3px;
  padding-left: 6px;
`;

const SorryMessage = styled.h2`
  font-family: "Cabin", sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  margin-top: 0px;
  margin-bottom: 25px;
`;

const NotFound = () => {
  return (
    <NotFoundWrapper>
      <NotFound404>
        <PageNotfound>Oops! Page not found</PageNotfound>
        <Message404>
          <Span404>4</Span404>
          <Span404>0</Span404>
          <Span404>4</Span404>
        </Message404>
      </NotFound404>
      <SorryMessage>
        we are sorry, but the page you requested was not found
      </SorryMessage>
    </NotFoundWrapper>
  );
};

export default NotFound;
