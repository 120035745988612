import React from "react";
import styled from "styled-components";
import bg from "../../assets/img/splash.jpg";
import SplashNavbar from "../splashNavbar";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "../pages/login";
import Signup from "../pages/signup";
import Confirmation from "../pages/confirmation";
import UnderReview from "../pages/underReview";
import forgotPassword from "../forgotPassword";
import ChangePassword from "./../changePassword";

const Background = styled.div`
  background-image: url(${bg});
  height: 100vh;
  background-size: cover;
  background-position: center center;
  position: relative;
  top: 0;
  bottom: 0;
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.65);
  top: 0;
  bottom: 0;
  width: 100%;
`;

const Splash = props => {
  return (
    <Background>
      <BackgroundOverlay>
        <SplashNavbar />
        <Switch>
          <Route
            path="/splash/underreview"
            exact
            render={() => <UnderReview />}
          />
          <Route
            path="/splash/login"
            exact
            render={() => (
              <Login onChangeAuthentication={props.onChangeAuthentication} />
            )}
          />
          <Route
            path="/splash/forgotpassword"
            exact
            component={forgotPassword}
          />
          <Route
            path="/splash/changepassword"
            exact
            component={ChangePassword}
          />
          <Route path="/splash/signup" exact component={Signup} />
          <Route
            path="/splash/confirmation"
            exact
            render={() => (
              <Confirmation
                onChangeAuthentication={props.onChangeAuthentication}
              />
            )}
          />
          <Redirect from="/splash" exact to="/splash/login" />
          <Redirect to="/not-found" />
        </Switch>
      </BackgroundOverlay>
    </Background>
  );
};

export default Splash;
