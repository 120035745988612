import React from "react";
import { withRouter } from "react-router-dom";
import Form from "./common/form";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import SplashMessage from "./splashMessage";
import axios from "axios";
import environment from "../environment/environment";

const { API_URL } = environment;

const SyncMessage = styled.div`
  font-size: 10px;
`;

class SignupForm extends Form {
  state = {
    account: {
      email: "",
      company: "",
      password: "",
      confirmPassword: "",
    },
    errors: {},
    user: null,
    isSigningUp: false,
    toast: null,
  };

  validate = () => {
    const { account } = this.state;
    const errors = {};

    const passwordLengthRegex = /^(?=.{6,})/;
    const passwordCapitalRegex = /^(?=.*[A-Z])/;
    const passwordNumericRegex = /^(?=.*[0-9])/;
    const paasswordSymbolRegex = /(?=.[!@#\$%\^&])/;

    if (account.email.trim() == "") errors.email = "Email is required.";
    if (account.company.trim() == "")
      errors.company = "Company name is required.";
    if (account.password.trim() == "")
      errors.password = "Password is required.";
    if (!passwordLengthRegex.test(account.password.trim()))
      errors.password = "Password must be atleast 6 characters long.";
    if (!passwordCapitalRegex.test(account.password.trim()))
      errors.password = "Password must contain atleast 1 uppercase letter.";
    if (!passwordNumericRegex.test(account.password.trim()))
      errors.password = "Password must contain atleast 1 numeric character.";
    if (!paasswordSymbolRegex.test(account.password.trim()))
      errors.password = "Password must contain atleast 1 symbolic character.";
    if (account.confirmPassword.trim() == "")
      errors.confirmPassword = "You must confirm your password.";
    if (account.confirmPassword.trim() !== account.password.trim()) {
      errors.confirmPassword = "Your passwords must match.";
      console.log("I got here");
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };

  validateOnChange = ({ name, value }) => {
    const errors = { ...this.state.errors };
    const { account } = this.state;
    if (name === "email") {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (value.trim() === "") {
        errors[name] = "Email is required.";
      } else if (!emailRegex.test(value.trim().toLowerCase()))
        errors[name] = "This is not a valid email";
      else delete errors[name];
    }
    if (name === "company") {
      if (value.trim() === "") {
        errors[name] = "Company name is required.";
      } else delete errors[name];
    }
    if (name === "password") {
      const passwordLengthRegex = /^(?=.{6,})/;
      const passwordCapitalRegex = /^(?=.*[A-Z])/;
      const passwordNumericRegex = /^(?=.*[0-9])/;
      const passwordSymbolRegex = /(?=.[!@#\$%\^&])/;
      if (value.trim() === "") return "Password is required.";
      else if (!passwordLengthRegex.test(value.trim()))
        errors[name] = "Password must be atleast 6 characters long.";
      else if (!passwordCapitalRegex.test(value.trim()))
        errors[name] = "Password must contain atleast 1 uppercase letter.";
      else if (!passwordNumericRegex.test(value.trim()))
        errors[name] = "Password must contain atleast 1 numeric character.";
      else if (!passwordSymbolRegex.test(value.trim())) {
        console.log(value.trim());
        errors[name] = "Password must contain atleast 1 symbolic character.";
      } else delete errors[name];

      if (value.trim() !== account.confirmPassword)
        errors["confirmPassword"] = "Passwords do not match";
      else delete errors["confirmPassword"];
    }
    if (name === "confirmPassword") {
      if (value.trim() === "") errors[name] = "You must confirm your password";
      else if (value.trim() !== account.password)
        errors[name] = "Passwords do not match";
      else delete errors[name];
    }
    return errors;
  };

  handleChange = ({ target: input }) => {
    const errors = this.validateOnChange(input);

    const account = { ...this.state.account };
    account[input.name] = input.value;
    this.setState({ account, errors: errors || {} });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.setState({ isSigningUp: true });

    const { account } = this.state;

    this.setState({
      toast: {
        message: "Signing up.",
        isSuccess: true,
      },
    });

    try {
      const { data: companies } = await axios.get(`${API_URL}/api/company/`);
      let doesCompanyExist = false;
      companies.forEach((company) => {
        if (
          company.companyName.toLowerCase() === account.company.toLowerCase()
        ) {
          doesCompanyExist = true;
        }
      });
      if (doesCompanyExist) {
        const toast = {
          message: "Company name already exists",
          isSuccess: false,
        };
        this.setState({ isSigningUp: false, toast });
        return;
      }
    } catch (err) {
      const toast = {
        message: "Network error, please try again later",
        isSuccess: false,
      };
      this.setState({ isSigningUp: false, toast });
    }
    try {
      const user = await Auth.signUp({
        username: account.email,
        password: account.password,
      });
      const response = await axios.post(`${API_URL}/api/company`, {
        email: account.email,
        thirdPartyID: user.userSub,
        companyName: account.company,
      });

      await this.props.history.push({
        pathname: "/splash/confirmation",
        state: {
          user: {
            email: account.email,
            password: account.password,
          },
        },
      });
    } catch (err) {
      const toast = {
        message: err.message,
        isSuccess: false,
      };
      this.setState({ isSigningUp: false, toast });
    }
  };

  parseEvents(oldEvents) {
    console.log(oldEvents);
  }

  render() {
    const { account, errors, isSigningUp, toast } = this.state;

    let message = null;

    if (toast !== null)
      message = (
        <SplashMessage isSuccess={toast.isSuccess} message={toast.message} />
      );

    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          {message}
          {this.renderInput(
            "Email",
            "email",
            account.email,
            errors.email,
            "email"
          )}
          {this.renderInput(
            "Company Name",
            "company",
            account.company,
            errors.company,
            "text"
          )}
          {this.renderInput(
            "Password",
            "password",
            account.password,
            errors.password,
            "password"
          )}
          {this.renderInput(
            "Confirm Password",
            "confirmPassword",
            account.confirmPassword,
            errors.confirmPassword,
            "password"
          )}

          {this.renderButton("SIGN UP", isSigningUp)}
        </form>
      </React.Fragment>
    );
  }
}

export default withRouter(SignupForm);
