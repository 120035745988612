import React, { Component } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import environment from '../../environment/environment';

const { INTEGRATION_SWAGGER_URL } = environment;

class Documentation extends Component {
  componentDidMount() { }

  componentWillMount() {
    const { onPageChange } = this.props;
    onPageChange("Documentation");
  }

  render() {
    const url = INTEGRATION_SWAGGER_URL
    return (
      <SwaggerUI url={url} />
    );
  }
}

export default Documentation;
