const development = {
  API_URL: "https://integration-service.dashrsystems-dev.com",
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_MOA5FkyUZ",
    USER_POOL_WEB_ID: "56qj6jkfc18lavu84vg6ee7ljg",
  },
  INTEGRATION_SWAGGER_URL:
    "https://api.swaggerhub.com/apis/jsherman10/Dashr.Integration.WebService/development-1.0",
};
const production = {
  API_URL: "https://integration-service.dashrsystems.com",
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_dKvrj61Fa",
    USER_POOL_WEB_ID: "75dee6vbtcskt4nvn37tu1o2me",
  },
  INTEGRATION_SWAGGER_URL:
    "https://api.swaggerhub.com/apis/jsherman10/Old.Integration.WebService/production-1.0",
};

const environment =
  process.env.REACT_APP_STAGE === "production" ? production : development;

export default environment;
