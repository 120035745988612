import React, { Component } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import { css } from "@emotion/core";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import environment from '../../environment/environment';

const { API_URL } = environment;

const override = css`
  display: block;
  margin-left 10px;
  vertical-align: middle;
`;

const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
`;

class ApiKey extends Component {
  state = {
    apiKey: null,
    thirdPartyID: null,
    isLoading: true,
    didFetchAPIKey: false
  };

  async componentWillMount() {
    const { onPageChange } = this.props;
    onPageChange("API Key");

    try {
      const coach = await Auth.currentUserInfo();
      const thirdPartyID = coach.attributes.sub;
      this.setState({ thirdPartyID });

      let { data: company } = await axios.get(
        `${API_URL}/api/company/${thirdPartyID}`
      );

      console.log(company);

      this.setState(
        {
          apiKey: company.apiKey
        },
        () => {
          this.setState({ isLoading: false, didFetchAPIKey: true });
        }
      );
    } catch (err) {
      // Display Error Message Internet Connectivity Error ** TO DO **
      console.log(err);
      this.setState({ isLoading: false, didFetchAPIKey: false });
    }
  }

  render() {
    const { isLoading, apiKey, didFetchAPIKey } = this.state;
    console.log(apiKey);

    if (isLoading && !didFetchAPIKey) {
      return (
        <CenteredWrapper>
          Fetching API key...
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={20}
            color={"#4a4a4a"}
            loading={isLoading}
          />
        </CenteredWrapper>
      );
    } else if (!isLoading && !didFetchAPIKey) {
      return (
        <CenteredWrapper>
          Could not fetch the API key, please try again.
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={20}
            color={"#4a4a4a"}
            loading={isLoading}
          />
        </CenteredWrapper>
      );
    }
    return (
      <CenteredWrapper>
        <h1>
          <b>API Key</b>: {apiKey}
        </h1>
      </CenteredWrapper>
    );
  }
}

export default ApiKey;
