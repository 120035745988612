import React from "react";
import Form from "./common/form";
import { Auth } from "aws-amplify";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import SplashMessage from "./splashMessage.jsx";
import axios from "axios";
import environment from '../environment/environment';

const { API_URL } = environment;

const StyledLink = styled(Link)`
  color: #1d88f2;
`;

const ForgotPassword = styled.div`
  text-align: center;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  margin: 15px 0px;
`;

class LoginForm extends Form {
  state = {
    account: {
      email: "",
      password: ""
    },
    errors: {},
    toast: null,
    isLoggingIn: false
  };

  validate = () => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const { account } = this.state;
    const errors = {};

    if (account.email.trim() === "") errors.email = "Email is required.";
    else if (!emailRegex.test(account.email.trim()))
      errors["email"] = "Email is not valid.";
    if (account.password.trim() == "")
      errors["password"] = "Password is required.";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  validateProperty = ({ name, value }) => {
    if (name === "email") {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value.trim() === "") return "Email is required.";
      else if (!emailRegex.test(value.trim())) return "Email is not valid";
    }
    if (name === "password") {
      if (value.trim() === "") return "Password is required.";
    }

    return null;
  };

  handleChange = ({ target: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else delete errors[input.name];

    const account = { ...this.state.account };
    account[input.name] = input.value;

    // this.setState({ account, errors });
    this.setState({ account, errors });
  };

  handleSubmit = async event => {
    event.preventDefault();
    let toast = null;
    this.setState({ toast });
    const { account } = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      return;
    }
    this.setState({ isLoggingIn: true });

    try {
      await Auth.signIn(account.email, account.password);
      const company = await Auth.currentUserInfo();
      const thirdPartyID = company.attributes.sub;
      console.log(`${API_URL}/api/company/${thirdPartyID}`)

      let { data: retrievedCompany } = await axios.get(
        `${API_URL}/api/company/${thirdPartyID}`
      );
      console.log(retrievedCompany);
      console.log("ISAPPROVED");
      console.log(retrievedCompany.isApproved);
      if (!retrievedCompany.isApproved) {
        this.props.history.push("/splash/underreview");
      } else {
        this.props.onChangeAuthentication(true);
        this.props.history.push("/");
      }
    } catch (e) {
      console.log(e);
      toast = {
        message: e.message,
        isSuccess: false
      };
      this.setState({ isLoggingIn: false, toast });
    }
  };

  render() {
    const { account, errors, isLoggingIn, toast } = this.state;

    let message = null;

    if (toast !== null)
      message = (
        <SplashMessage isSuccess={toast.isSuccess} message={toast.message} />
      );

    return (
      <React.Fragment>
        {message}
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("Email", "email", account.email, errors.email)}
          {this.renderInput(
            "Password",
            "password",
            account.password,
            errors.password,
            "password"
          )}
          <ForgotPassword>
            <StyledLink to="/splash/forgotpassword">
              Forgot Password?
            </StyledLink>
          </ForgotPassword>
          {this.renderButton("LOGIN", isLoggingIn)}
        </form>
      </React.Fragment>
    );
  }
}

export default withRouter(LoginForm);
