import React from "react";
import SignupForm from "../signupForm";
import SplashCard from "../splashCard";

const Signup = () => {
  return (
    <SplashCard>
      <SignupForm />
    </SplashCard>
  );
};

export default Signup;
